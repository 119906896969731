import React from 'react';
import './about.css'; // Importing the CSS file
import '../../colors.css'; // Importing the color palette
import { FaClock, FaCogs, FaRocket, FaShieldAlt, FaExpand, FaPlug, FaUsers, FaCheckCircle, FaHeadset, FaChartBar, FaTools } from 'react-icons/fa';
import about from '../../Svg/about.png';
const About = () => {
    const features = [
        { icon: <FaClock />, text: 'Fast Revert Back' },
        { icon: <FaCogs />, text: 'Deadline Management' },
        { icon: <FaTools />, text: 'Custom Solutions' },
        { icon: <FaRocket />, text: 'High Performance' },
        { icon: <FaShieldAlt />, text: 'Enhanced Security' },
        { icon: <FaExpand />, text: 'Scalability' },
        { icon: <FaPlug />, text: 'Seamless Integration' },
        { icon: <FaUsers />, text: 'Client-Centric Approach' },
        { icon: <FaCheckCircle />, text: 'Reliability' },
        { icon: <FaHeadset />, text: '24/7 Support' },
        { icon: <FaChartBar />, text: 'Real-Time Analytics' },
        { icon: <FaTools />, text: 'Collaboration Tools' },
    ];

    return (
        <div className="about-page">
            {/* About Section */}
            <section className="about-section">
                <div className="about-header">
                    <h1>About Us</h1>
                    <p>We deliver AI-driven IT solutions tailored for your success.</p>
                </div>
                <div className="about-hero-section">
                    <div className="about-text">
                        <p>
                            Welcome to <strong>Contact Iq Solutions</strong>, your trusted partner in innovative IT solutions. We
                            specialize in providing top-tier services in the areas of web development, mobile application development,
                            and advanced AI-driven solutions tailored to your business needs.
                        </p>
                        <p>
                            Our mission is to empower businesses through technology, ensuring seamless operations and accelerated growth.
                            With expertise spanning years, we are committed to delivering excellence in every solution we provide.
                        </p>
                        <p>
                            Whether you're looking to enhance user experience, optimize your workflows, or embrace cutting-edge
                            AI technologies, we have the skills, tools, and passion to make it happen.
                        </p>
                    </div>
                    <div className="about-image">
                        <img src={about} alt="About ContactIq Solutions" />
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section className="features">
                <div className="features-section">
                    <h2>Our Features</h2>
                    <p>Explore the core features that make us the ideal choice for your IT needs:</p>
                    <div className="features-list">
                        {features.map((feature, index) => (
                            <div className="feature-box" key={index}>
                                <div className="feature-icon">{feature.icon}</div>
                                <p>{feature.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default About;
