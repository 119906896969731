// src/components/common/footer/Footer.jsx

import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import './footer.css';
import logo from "../../../Svg/LOGOS.png";  // Import the logo

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-logo">
          <img src={logo} alt="ContactIq Solutions Logo" className="footer-logo-img" />
        </div>
        <div className="footer-links">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div className="footer-services">
          <h3>Our Services</h3>
          <ul>
            <li><Link to="/service/web-development">Web Development</Link></li>
            <li><Link to="/service/app-development">Mobile App Development</Link></li>
            <li><Link to="/service/ai-powered-solutions">AI Solutions</Link></li>
            <li><Link to="/service/cloud-computing">Cloud Computing</Link></li>
            <li><Link to="/service/e-commerce-solutions">E-Commerce Solutions</Link></li>
            <li><Link to="/service/cybersecurity">Cyber security</Link></li>
          </ul>
        </div>
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <p>Phone: (123) 456-7890</p>
          <p>Email: info@contactiq.com</p>
          <p>Location: 123 Tech Lane, City, Country</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 ContactIq Solutions. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
