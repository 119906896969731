import React from 'react';
import './servicePage.css'; // Importing the shared CSS file for services
import '../../../colors.css'; // Importing the color palette
import { servicesData } from '../../services/servicesData.js'; // Import service data
import ServicesDetail from '../../services/servicesdetail'; // Import ServicesDetail component
import '../../services/services.css';
import logo from '../../../Svg/cloud.png';
const CloudComputing = () => {
    return (
        <div className="service-page">
            {/* Service Section */}
            <section className="service-section">
                <div className="services-header-pages">
                    <h1>Cloud Computing</h1>
                    <p>
                        Unlock the power of the cloud with scalable, secure, and cost-effective solutions tailored to your business.
                    </p>
                </div>
                <div className="service-hero-section">
                    <div className="service-image">
                        <img src={logo} alt="Cloud Computing" />
                    </div>
                    <div className="service-text">
                        <div className="focus-areas">
                            <p>
                                <strong>Cloud Migration:</strong> Seamlessly move your business operations to the cloud, ensuring minimal disruption.
                            </p>
                            <p>
                                <strong>Infrastructure Management:</strong> Efficiently manage cloud infrastructure to maximize uptime and performance.
                            </p>
                            <p>
                                <strong>Cloud-Native Application Development:</strong> Build applications designed to leverage the full potential of cloud platforms.
                            </p>
                            <p>
                                <strong>Data Security:</strong> Protect sensitive data with robust security protocols and compliance measures.
                            </p>
                            <p>
                                <strong>Cost Optimization:</strong> Optimize cloud costs while ensuring access to the necessary resources.
                            </p>
                            <p>
                                <strong>Scalability:</strong> Expand your cloud resources effortlessly to meet growing business demands.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Additional Services Section */}
            <section className="services-container">
                {servicesData.map((service) => (
                    <ServicesDetail key={service.id} service={service} />
                ))}
            </section>
        </div>
    );
};

export default CloudComputing;
