// src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './colors.css';  // Import your global styles
import Header from "./components/common/header/Header";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Footer from "./components/common/footer/Footer";
import Services from "./components/services/services";
import Contact from "./components/Contact/contect";
import WebDevelopment from './components/common/servicespages/WebDevelopment';
import AppDevelopment from './components/common/servicespages/AppDevelopment';
import AIPoweredSolutions from './components/common/servicespages/AI-PoweredSolutions.jsx';
import CloudComputing from "./components/common/servicespages/CloudComputing.jsx";
import ECommerceSolutions from "./components/common/servicespages/ECommerceSolutions";
import Cybersecurity from "./components/common/servicespages/Cybersecurity";


const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />

        {/* Service Detail Routes */}
        <Route path="/service/web-development" element={<WebDevelopment />} />
        <Route path="/service/app-development" element={<AppDevelopment />} />
        <Route path="/service/ai-powered-solutions" element={<AIPoweredSolutions />} />
        <Route path="/service/cloud-computing" element={<CloudComputing />} />
        <Route path="/service/e-commerce-solutions" element={<ECommerceSolutions />} />
        <Route path="/service/cybersecurity" element={<Cybersecurity />} />

      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
