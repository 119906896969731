// src/components/services/servicesdetail.jsx

import React, { useEffect } from 'react'; // Import useEffect from react
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation from react-router-dom

const ServicesDetail = ({ service }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="service-card">
            <div className="service-icon">
                {service.icon}
            </div>
            <h2>{service.title}</h2>
            <p>{service.description}</p>

            {/* Link to the detailed service page */}
            <Link to={`/service/${service.title.toLowerCase().replace(/ /g, '-')}`} className="button-primary">
                Learn More
            </Link>
        </div>
    );
};

export default ServicesDetail;
