
import React from 'react';
import './contact.css';  // Import the CSS file for styles

const Contact = () => {
    return (
        <div className="contact-page">
            {/* Hero Section */}
            <section className="hero-section">
                <div className="contact-text">
                    <h1>Contact Us</h1>
                    <p>Looking to get in touch with us? We offer multiple ways for you to reach out based on your needs. Explore all your options below.</p>
                </div>
            </section>

            {/* Support and Contact Details Section */}
            <section className="contact-details">
                <div className="support-section">
                    <h2>Get Support</h2>
                    <p>If you're experiencing technical issues or need assistance with our IT services, browse through our knowledge base or get help from our community forums.</p>
                    <p>Customers who have signed up for our services can also access dedicated support via our client portal for advanced solutions.</p>
                </div>

                <div className="inquiry-details">
                    <h2>Contact Details</h2>
                    <p>For general inquiries, feel free to reach out to us through the following methods:</p>
                    <p><strong>Phone:</strong> (123) 456-7890</p>
                    <p><strong>Email:</strong> support@technologuyhub.com</p>
                    <p><strong>Location:</strong> 456 Tech Avenue, City, Country</p>
                </div>
            </section>
        </div>
    );
};

export default Contact;
