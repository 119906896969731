// servicesData.js

export const servicesData = [
    {
        id: 1,
        title: "Web Development",
        description: "Build robust, scalable, and modern websites to elevate your online presence.",
        icon: "🌐", // Replace with actual icons or images
    },
    {
        id: 2,
        title: "App Development",
        description: "Design intuitive and powerful mobile apps tailored to your business needs.",
        icon: "📱",
    },
    {
        id: 3,
        title: "AI-Powered Solutions",
        description: "Leverage AI to transform data into actionable insights for smarter decision-making.",
        icon: "🤖",
    },
    {
        id: 4,
        title: "Cloud Computing",
        description: "Deploy scalable and secure cloud-based solutions to optimize your infrastructure.",
        icon: "☁️",
    },

    {
        id: 5,
        title: "E-Commerce Solutions",
        description: "Create customized, secure, and easy-to-manage e-commerce platforms.",
        icon: "🛒",
    },
    {
        id: 6,
        title: "Cybersecurity",
        description: "Protect your business from digital threats with our advanced cybersecurity solutions.",
        icon: "🔐",
    },

];
