import React from 'react';
import './servicePage.css'; // Importing the shared CSS file for services
import '../../../colors.css'; // Importing the color palette
import { servicesData } from '../../services/servicesData.js'; // Import service data
import ServicesDetail from '../../services/servicesdetail'; // Import ServicesDetail component
import '../../services/services.css';
import logo from '../../../Svg/ai.png';

const AIPoweredSolutions = () => {
    return (
        <div className="service-page">
            {/* Service Section */}
            <section className="service-section">
                <div className="services-header-pages">
                    <h1>AI-Powered Solutions</h1>
                    <p>AI-driven solutions transforming industries and empowering businesses.</p>
                </div>
                <div className="service-hero-section">
                    <div className="service-image">
                        <img src={logo} alt="AI-Powered Solutions" />
                    </div>
                    <div className="service-text">
                        <div className="focus-areas">
                            <p>
                                <strong>Automated Processes:</strong> Streamline repetitive tasks, reducing time and cost inefficiencies.
                            </p>
                            <p>
                                <strong>Improved Decision-Making:</strong> Harness advanced analytics to derive actionable insights.
                            </p>
                            <p>
                                <strong>Data-Driven Predictions:</strong> Use predictive modeling to anticipate trends and market shifts.
                            </p>
                            <p>
                                <strong>Enhanced User Experiences:</strong> Personalize interactions to improve customer satisfaction.
                            </p>
                            <p>
                                <strong>Cost Optimization:</strong> Leverage AI to identify cost-saving opportunities and boost ROI.
                            </p>
                            <p>
                                <strong>Scalable Solutions:</strong> Adaptable AI tools to meet evolving business needs.
                            </p>
                        </div>
                    </div>

                </div>
            </section>
            {/* Additional Services Section */}
            <section className="services-container">
                {servicesData.map((service) => (
                    <ServicesDetail key={service.id} service={service} />
                ))}
            </section>
        </div>
    );
};

export default AIPoweredSolutions;
