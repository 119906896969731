import React from 'react';
import './servicePage.css'; // Importing the shared CSS file for services
import '../../../colors.css'; // Importing the color palette
import { servicesData } from '../../services/servicesData.js'; // Import service data
import ServicesDetail from '../../services/servicesdetail'; // Import ServicesDetail component
import '../../services/services.css';
import logo from '../../../Svg/mobile.png';
const AppDevelopment = () => {
    return (
        <div className="service-page">
            {/* Service Section */}
            <section className="service-section">
                <div className="services-header-pages">
                    <h1>App Development</h1>
                    <p>
                        We offer state-of-the-art app development services that help you engage your audience and streamline business operations. Our team is dedicated to delivering custom, feature-rich, and highly efficient mobile applications.
                    </p>
                </div>
                <div className="service-hero-section">
                    <div className="service-image">
                        <img src={logo} alt="App Development" />
                    </div>
                    <div className="service-text">
                        <div className="focus-areas">
                            <p>
                                <strong>Cross-Platform Development:</strong> Develop apps that work seamlessly across multiple platforms, including iOS, Android, and the web.
                            </p>
                            <p>
                                <strong>UI/UX Design:</strong> Craft beautiful, user-friendly interfaces to provide a top-notch user experience.
                            </p>
                            <p>
                                <strong>Performance Optimization:</strong> Ensuring fast and smooth performance, even with complex features and large data sets.
                            </p>
                            <p>
                                <strong>Security and Scalability:</strong> Building secure, scalable applications that grow with your business needs.
                            </p>
                            <p>
                                <strong>App Store Optimization:</strong> Ensuring your app ranks higher in app stores to boost visibility and downloads.
                            </p>
                            <p>
                                <strong>Continuous Support and Maintenance:</strong> Offering post-launch support to keep your app updated and running smoothly.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Additional Services Section */}
            <section className="services-container">
                {servicesData.map((service) => (
                    <ServicesDetail key={service.id} service={service} />
                ))}
            </section>
        </div>
    );
};

export default AppDevelopment;
