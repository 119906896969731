import React from 'react';
import './servicePage.css'; // Importing the shared CSS file for services
import '../../../colors.css'; // Importing the color palette
import { servicesData } from '../../services/servicesData.js'; // Import service data
import ServicesDetail from '../../services/servicesdetail'; // Import ServicesDetail component
import '../../services/services.css';
import logo from '../../../Svg/cyber.png';
const Cybersecurity = () => {
    return (
        <div className="service-page">
            {/* Service Section */}
            <section className="service-section">
                <div className="services-header-pages">
                    <h1>Cybersecurity</h1>
                    <p>
                        Safeguard your business from cyber threats and data breaches with our comprehensive cybersecurity services.
                    </p>
                </div>
                <div className="service-hero-section">
                    <div className="service-image">
                        <img src={logo} alt="Cybersecurity" />
                    </div>
                    <div className="service-text">
                        <div className="focus-areas">
                            <p>
                                <strong>Threat Detection:</strong> Identify and neutralize potential threats before they impact your business.
                            </p>
                            <p>
                                <strong>Vulnerability Assessments:</strong> Uncover security weaknesses and take proactive steps to address them.
                            </p>
                            <p>
                                <strong>Security Audits:</strong> Conduct in-depth evaluations to ensure compliance with industry standards and regulations.
                            </p>
                            <p>
                                <strong>Incident Response:</strong> Minimize damage and recover quickly from cyber attacks with expert response planning.
                            </p>
                            <p>
                                <strong>Network Security:</strong> Protect your network infrastructure with robust firewalls and intrusion detection systems.
                            </p>
                            <p>
                                <strong>Data Protection:</strong> Secure sensitive data to prevent unauthorized access and ensure business continuity.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Additional Services Section */}
            <section className="services-container">
                {servicesData.map((service) => (
                    <ServicesDetail key={service.id} service={service} />
                ))}
            </section>
        </div>
    );
};

export default Cybersecurity;
