import React from 'react';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import './Home.css';
import frountimg from "../../Svg/main.png";
import aboutimage from "../../Svg/about.png";
import { servicesData } from '../services/servicesData.js';  // Import service data
import ServicesDetail from '../services//servicesdetail';  // Import ServicesDetail component
import '../services//services.css';



const Home = () => {


  return (
    <div className="home">

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-text">
          <h1>Expert IT Solutions for Your Business</h1>
          <p>
            At Connect IQ Solutions, we specialize in providing cutting-edge IT services that empower businesses to scale and innovate. From cloud solutions to data security, we’re your trusted partner in digital transformation.
          </p>
          <div className="hero-buttons">

            <Link to="/services" >View Services</Link>
          </div>
        </div>
        <div className="hero-image">
          <img src={frountimg} alt="ContactIQ Solutions" />
        </div>
      </section>

      {/* About Us Section */}
      <section className="habout-section">
        <div className="habout-us">

          <motion.div
            className="habout-image"
            initial={{ scale: 0.8, rotate: 15 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{
              type: "spring",
              stiffness: 150,
              damping: 12,
              delay: 0.2,
            }}
          >
            <img src={aboutimage} alt="Logo" />
          </motion.div>

          <motion.div
            className="habout-text"
            initial={{ x: -200, rotate: -10 }}
            animate={{ x: 0, rotate: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 10 }}
          >
            <h1>About Us</h1>
            <p>
              Welcome to <strong>Connect IQ Solutions</strong>, your trusted partner in innovative IT solutions.
              We specialize in providing top-tier services in the areas of web development, mobile application development,
              and advanced AI-driven solutions tailored to your business needs.
            </p>
          </motion.div>

        </div>
      </section>

      <section className="services-container">
        {servicesData.map((service) => (
          <ServicesDetail key={service.id} service={service} />
        ))}
      </section>
    </div>
  );
};

export default Home;
