import React from 'react';
import './servicePage.css'; // Importing the shared CSS file for services
import '../../../colors.css'; // Importing the color palette
import { servicesData } from '../../services/servicesData.js'; // Import service data
import ServicesDetail from '../../services/servicesdetail'; // Import ServicesDetail component
import '../../services/services.css';
import logo from '../../../Svg/web.png';

const WebDevelopment = () => {
    return (
        <div className="service-page">
            {/* Service Section */}
            <section className="service-section">
                <div className="services-header-pages">
                    <h1>Web Development</h1>
                    <p>Empower your business with AI-driven IT services tailored to accelerate growth and deliver excellence.</p>
                </div>
                <div className="service-hero-section">
                    <div className="service-image">
                        <img src={logo} alt="Web Development" />
                    </div>
                    <div className="service-text">
                        <div className="focus-areas">
                            <p>
                                <strong>User Experience (UX) Design:</strong> Crafting intuitive and engaging user journeys to maximize customer satisfaction.
                            </p>
                            <p>
                                <strong>Cutting-Edge Technologies:</strong> Leveraging the latest frameworks and tools like ReactJS, Angular, Node.js, and more for top-tier performance.
                            </p>
                            <p>
                                <strong>Security:</strong> Implementing robust security protocols to safeguard your website against vulnerabilities.
                            </p>
                            <p>
                                <strong>Scalability:</strong> Developing solutions that grow with your business, handling increased traffic and expanding functionalities effortlessly.
                            </p>
                            <p>
                                <strong>SEO-Friendly Development:</strong> Building websites optimized for search engines to enhance visibility and organic traffic.
                            </p>
                            <p>
                                <strong>Speed and Performance:</strong> Ensuring lightning-fast loading times and seamless navigation.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Additional Services Section */}
            <section className="services-container">
                {servicesData.map((service) => (
                    <ServicesDetail key={service.id} service={service} />
                ))}
            </section>
        </div>
    );
};

export default WebDevelopment;
