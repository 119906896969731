import React from 'react';
import { servicesData } from './servicesData'; // Import service data
import ServicesDetail from './servicesdetail'; // Import ServicesDetail component
import './services.css'; // Import the CSS file
import logo from '../../Svg/services.png'

const Services = () => {
    return (
        <div className="services-page">
            <section className="services-header">
                <div className="services-image">
                    <img src={logo} alt="About Us" />
                </div>
                <div className="services-details-header">
                    <h1>We Provide IT Solutions</h1>
                    <p>
                        Welcome to <strong>ContactIq Solutions</strong>, your trusted partner in innovative IT solutions. We specialize in providing top-tier services in the areas of web development, mobile application development, and advanced AI-driven solutions tailored to your business needs.
                    </p>
                </div>
            </section>

            <section className="services-container">
                {servicesData.map((service) => (
                    <ServicesDetail key={service.id} service={service} />
                ))}
            </section>
        </div>
    );
};

export default Services;
