import React from 'react';
import './servicePage.css'; // Importing the shared CSS file for services
import '../../../colors.css'; // Importing the color palette
import { servicesData } from '../../services/servicesData.js'; // Import service data
import ServicesDetail from '../../services/servicesdetail'; // Import ServicesDetail component
import '../../services/services.css';
import logo from '../../../Svg/ecom.png';
const ECommerceSolutions = () => {
    return (
        <div className="service-page">
            {/* Service Section */}
            <section className="service-section">
                <div className="services-header-pages">
                    <h1>E-Commerce Solutions</h1>
                    <p>
                        Transform your business with custom e-commerce solutions tailored to drive growth and elevate user experience.
                    </p>
                </div>
                <div className="service-hero-section">
                    <div className="service-image">
                        <img src={logo} alt="E-Commerce Solutions" />
                    </div>
                    <div className="service-text">
                        <div className="focus-areas">
                            <p>
                                <strong>Custom Store Design:</strong> Create unique, visually stunning online stores that reflect your brand identity.
                            </p>
                            <p>
                                <strong>Payment Gateway Integration:</strong> Ensure seamless and secure transactions with multiple payment options.
                            </p>
                            <p>
                                <strong>Order Management Systems:</strong> Simplify order tracking, inventory management, and shipping processes.
                            </p>
                            <p>
                                <strong>Responsive Design:</strong> Optimize your e-commerce platform for all devices to provide a seamless shopping experience.
                            </p>
                            <p>
                                <strong>Security:</strong> Safeguard customer data with robust security measures and compliance protocols.
                            </p>
                            <p>
                                <strong>Scalability:</strong> Build platforms that grow alongside your business, handling increased traffic and transactions effortlessly.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Additional Services Section */}
            <section className="services-container">
                {servicesData.map((service) => (
                    <ServicesDetail key={service.id} service={service} />
                ))}
            </section>
        </div>
    );
};

export default ECommerceSolutions;
