import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './header.css'; // Your custom CSS file
import logo from '../../../Svg/LOGOS.png'; // Path to your company logo image
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMenuClose = () => {
    setMenuOpen(false);
    setDropdownOpen(false);
  };
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate('/');
    handleMenuClose(); // Ensure menu closes when clicking the logo
  };
  return (
    <nav className="navbar">
      {/* Logo */}
      <div className="logo" onClick={handleLogoClick} style={{ margin: 0, padding: 0 }}>
        <img src={logo} alt="Company Logo" className="logo-image" />
      </div>
      {/* Hamburger Icon for Mobile */}
      <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      {/* Menu Items */}
      <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
        <li>
          <Link to="/" onClick={handleMenuClose}>Home</Link>
        </li>
        <li>
          <Link to="/about" onClick={handleMenuClose}>About</Link>
        </li>
        <li className={`dropdown ${dropdownOpen ? 'open' : ''}`}>
          <Link
            to="/Services"
            onClick={(e) => {
              e.preventDefault();
              setDropdownOpen(!dropdownOpen);
            }}
          >
            Services
          </Link>
          <ul className="dropdown-menu">
            <li><Link to="/service/web-development" onClick={handleMenuClose}>Web Development</Link></li>
            <li><Link to="/service/app-development" onClick={handleMenuClose}>App Development</Link></li>
            <li><Link to="/service/ai-powered-solutions" onClick={handleMenuClose}>AI Powered Solutions</Link></li>
            <li><Link to="/service/cloud-computing" onClick={handleMenuClose}>Cloud Computing</Link></li>
            <li><Link to="/service/e-commerce-solutions" onClick={handleMenuClose}>E-Commerce Solutions</Link></li>
            <li><Link to="/service/cybersecurity" onClick={handleMenuClose}>Cybersecurity</Link></li>
          </ul>
        </li>
        <li>
          <Link to="/contact" onClick={handleMenuClose}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};
export default Header;